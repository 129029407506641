@tailwind base;
@tailwind components;
@tailwind utilities;

[x-cloak] { display: none !important; }

[data-status] {
  transform-style: preserve-3d;
}

[data-status='correct'] {
  @apply bg-letter-correct;
}

input[data-status='correct'] {
  @apply duration-500;
  transform: rotateY(360deg);
}

[data-status='wrong-spot'] {
  @apply bg-letter-wrong-spot;
}

input[data-status='wrong-spot']{
  @apply duration-500;
  transform: rotateY(360deg);
}

[data-status='not-in-word'] {
  @apply bg-letter-not-in-word;
}

input[data-status='not-in-word'] {
  @apply duration-500;
  transform: rotateY(360deg);
}
